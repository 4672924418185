import React from "react"
import { graphql } from "gatsby"

import Metadata from "../components/Metadata"
import ResourceThumbnail from "../components/ResourcesThumbnail"
import FooterPitch from "../components/FooterPitch"

import {
  Heading,
  Content,
  PageController,
  Page,
  List,
  // Filters,
  // Input,
  // Filter,
  // Option,
  // FilterHeading,
} from "./exercises"

export const pageQuery = graphql`
  query featureImagesQuery($featureImageIds: [String]!) {
    featureImages: allFile(filter: { id: { in: $featureImageIds } }) {
      edges {
        node {
          id
          childImageSharp {
            fixed(cropFocus: CENTER, width: 400, height: 300, quality: 90, toFormat: WEBP) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

const ResourcesPage = ({
  pageContext: {
    searches,
    resources,
    limit,
    skip,
    pageCount,
    currentPage,
  },
  data,
  location,
}) => {
  // const [page = 1, setPage] = useQueryParam("page", NumberParam)
  // const [search, setSearch] = useState("")
  
  const setPage = (page) => {
    window.location.assign(`/resources/${page > 1 ? page : ""}`);
  }

  return (
    <>
      <Metadata
        title="Learn about fitness, strength, health & well-being."
        description=""
        url={`${location.origin || "https://programme.app"}${location.pathname}`}
      />
      <Heading>
        <h1>
          <strong>Resources </strong>
          discussing strength, health & well-being.
        </h1>
      </Heading>
      <Content>
        {/* <Filters> */}
        {/*   <Input */}
        {/*     placeholder="Search Resources" */}
        {/*     value={search} */}
        {/*     onChange={e => setSearch(e.target.value)} */}
        {/*   /> */}
        {/*   <Filter> */}
        {/*     <a onClick={() => setSearch("")}> */}
        {/*       <FilterHeading>All Resources</FilterHeading> */}
        {/*     </a> */}
        {/*     {searches?.map(name => ( */}
        {/*       <a key={name} onClick={() => setSearch(name)}> */}
        {/*         <Option selected={search === name}>{name}</Option> */}
        {/*       </a> */}
        {/*     ))} */}
        {/*   </Filter> */}
        {/* </Filters> */}
        <List>
          {resources.map(r => (
            <ResourceThumbnail
              key={r.id}
              {...r}
              featureImage={
                data.featureImages.edges.filter(
                  i => i.node.id === r.featureImage
                )[0]
              }
            />
          ))}
          <PageController>
            {currentPage > 1 ? (
              <Page onClick={() => setPage(currentPage - 1)}>
                {"<-"} Previous
              </Page>
            ) : null}
            {currentPage > 5 ? (
              <span>
                <Page selected={pageCount === currentPage} onClick={() => setPage(1)}>
                  {1}
                </Page>
                <Page>...</Page>
              </span>
            ) : null}

            {[...new Array(pageCount).keys()]
              .slice(currentPage > 5 ? currentPage - 4 : 0, currentPage + 3)
              .map((i, idx) => (
                <Page
                  key={i}
                  selected={currentPage === i + 1}
                  onClick={() => setPage(i + 1)}
                >
                  {i + 1}
                </Page>
              ))}

            {pageCount - 3 > currentPage ? (
              <span>
                <Page>...</Page>
                <Page
                  selected={pageCount === currentPage}
                  onClick={() => setPage(pageCount)}
                >
                  {pageCount}
                </Page>
              </span>
            ) : null}
            {currentPage < pageCount ? (
              <Page onClick={() => setPage(currentPage + 1)}>
                Next ->
              </Page>
            ) : null}
          </PageController>
        </List>
      </Content>
      <FooterPitch />
    </>
  )
}

export default ResourcesPage
